import {
  isNonEmptyString,
  isObject,
  isString,
  isValidNumber,
} from '@rippling/utils/validationUtils';
import { EMPTY_OPTION_KEY, PERIOD_PLACEHOLDER_KEY } from './wordpress-content';

export const getObjectEntries = (obj: Record<string, any>) => {
  if (!isObject(obj)) {
    return [];
  }

  return Object.entries(obj);
};

/** Merges any json sources into a json target */
export const mergeDeepJson = <InputModel>(
  target: InputModel,
  ...sources: InputModel[]
): InputModel => {
  if (!sources.length) {
    return target;
  }

  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }

        mergeDeepJson(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return mergeDeepJson(target, ...sources);
};

/** Creates a deep clone of a json blob */
export const deepCloneJson = <InputModel>(json: InputModel): InputModel => {
  return JSON.parse(JSON.stringify(json));
};

/**
 * Inserts a value into json at a given data path.
 * If the data location doesn't exist, it will be created.
 */
export const insertIntoJson = (
  baseJson: Record<string, any> | undefined,
  dataPath: string,
  value: any
) => {
  if (!baseJson) {
    baseJson = {};
  }

  const attrsPathArr = dataPath.split('.');

  let currBase = baseJson;

  for (let i = 0; i < attrsPathArr.length; i++) {
    let currSegment = attrsPathArr[i];
    // we want to replace the empty option placeholder with an empty string
    if (currSegment === EMPTY_OPTION_KEY) {
      currSegment = '';
    }
    if (currSegment.match(new RegExp(PERIOD_PLACEHOLDER_KEY, 'g'))) {
      currSegment = currSegment.replace(
        new RegExp(PERIOD_PLACEHOLDER_KEY, 'g'),
        '.'
      );
    }

    // if we're on the last item, assign the value and skip the rest
    if (i === attrsPathArr.length - 1) {
      currBase[currSegment] = value;
      continue;
    }

    const nextSegment = attrsPathArr[i + 1];

    // if key does not exist, and the next segment is not the last
    if (!currBase[currSegment] && isString(nextSegment)) {
      const nextSegmentIsArrayIndex = isValidNumber(nextSegment);
      // if the next segment is a number, this should be an array...
      const currSegmentVal = nextSegmentIsArrayIndex ? [] : {};
      // set value to an empty object or array
      currBase[currSegment] = currSegmentVal;
    }
    // jump into our current segment in attrs
    currBase = currBase[currSegment];
  }

  return baseJson;
};

export const attemptToParseJson = (
  jsonString: any,
  fallback: any = null,
  logErrors: boolean = false
): any => {
  if (!isNonEmptyString(jsonString)) {
    return jsonString;
  }

  try {
    return JSON.parse(jsonString);
  } catch (err) {
    if (logErrors) {
      console.error('Failed to parse json:', err);
    }
  }

  return fallback || jsonString;
};

export const filterFalsyObjectKeys = (
  object: Record<string, any>,
  {
    keepZeros,
    keepEmptyStrings,
  }: { keepZeros?: boolean; keepEmptyStrings?: boolean } = {}
) => {
  const keys = Object.keys(object);
  keys.forEach((key) => {
    if (keepZeros && object[key] === 0) {
      return;
    }

    if (keepEmptyStrings && object[key] === '') {
      return;
    }

    if (!object[key]) {
      delete object[key];
    }
  });

  return object;
};
